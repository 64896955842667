.quill {
	width: 100%;
	height: 100%;
	min-height: 200px;
	display: flex;
	flex-direction: column;
}

.quill .ql-toolbar {
	border: 0;
	background-color: #f4f6f9;
	border-bottom: 2px solid #ffffff;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	flex: 0 0 50px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.quill .ql-toolbar .ql-formats {
	margin-right: 30px;
}

.quill .ql-container {
	flex: 1;
	background-color: #f4f6f9;
	border: 0;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	display: flex;
}

.quill .ql-container .ql-editor {
	flex: 1;
	height: auto;
}
